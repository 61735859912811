@mixin margin($val: 1, $pos: all) {
  @if $pos == all {
    margin: #{$val}px !important;
  } @else if $pos == x {
    margin-left: #{$val}px !important;
    margin-right: #{$val}px !important;
  } @else if $pos == y {
    margin-top: #{$val}px !important;
    margin-bottom: #{$val}px !important;
  } @else {
    margin-#{$pos}: #{$val}px !important;
  }
}

@mixin padding($val: 1, $pos: all) {
  @if $pos == all {
    padding: #{$val}px !important;
  } @else if $pos == x {
    padding-left: #{$val}px !important;
    padding-right: #{$val}px !important;
  } @else if $pos == y {
    padding-top: #{$val}px !important;
    padding-bottom: #{$val}px !important;
  } @else {
    padding-#{$pos}: #{$val}px !important;
  }
}

@mixin border($pos: all, $c: $c-d8, $val: 1px) {
  @if $pos == all {
    border: #{$val} solid #{$c};
  } @else if $pos == x {
    border-left: #{$val} solid #{$c};
    border-right: #{$val} solid #{$c};
  } @else if $pos == y {
    border-top: #{$val} solid #{$c};
    border-left: #{$val} solid #{$c};
  } @else {
    border-#{$pos}: #{$val} solid #{$c};
  }
}

@mixin bg-img($url: '') {
  background-image: url('/public_static/teachingassistant/#{$url}');
}

@mixin border($w, $c, $pos: all) {
  @if $pos == all {
    border: #{$w}px solid $c;
  } @else if $pos == x {
    border-left: #{$w}px solid $c;
    border-right: #{$w}px solid $c;
  } @else if $pos == y {
    border-top: #{$w}px solid $c;
    border-bottom: #{$w}px solid $c;
  } @else {
    border-#{$pos}: #{$w}px solid $c;
  }
}

@mixin triangle($direction: bottom, $w: 30, $h: 20, $c: $c-main) {
  @if $direction == bottom {
    @include border($w / 2, transparent, x);
    @include border($h, $c, top);
  }
  @if $direction == top {
    @include border($w / 2, transparent, x);
    @include border($h, $c, bottom);
  }
  @if $direction == left {
    @include border($h / 2, transparent, y);
    @include border($w, $c, right);
  }
  @if $direction == right {
    @include border($h / 2, transparent, y);
    @include border($w, $c, left);
  }
}

@function transFormFontSize($px) {
  // $rem: 1.38;
  $rem: 1;
  @return ($px * $rem)+px;
}
@function fitPix3($px) {
  // $rem: 1.38;
  $rem: 1;
  @return ($px * $rem)+px;
}
@function fitPix4($px) {
  // $rem: 1.38;
  $rem: 1.335;
  @return ($px / $rem)+px;
}

$white: #fff;
$c-main: #1890ff;
$black: #000;
$c333: #333;
$c666: #666;
$c999: #999;
$c-d8: #D8D8D8;
$c-eb: #EBEBEB;
$c-text: #505253;
$m-hook: #3473f0;
$wrap: 180px;
$header-height: 80px;
$root-font-size:750/10rem;
$font-size:375/10rem;

@media screen and (min-device-width:1080px) {
   #root {
    // background-color: #1890ff;
   }
}

.web-box {
  background-color: #fff;

  // max-width: 1080px;
  // overflow-x: hidden;
  overflow: auto !important;
  overflow: scroll !important;
  font-size: 28px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #292929;
  line-height: 48px;

  .item {
    clear: both;
    margin-top: 40px;
    margin-left: 38px;
    margin-right: 38px;
  }

  .right {
    margin-top: 38px;
    float: right;
    margin-right: 38px;
    font-size: 28px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 48px;
    margin-bottom: 30px;

  }

  .center {
    margin-top: 30px;
    clear: both;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
