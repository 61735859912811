@mixin margin($val: 1, $pos: all) {
  @if $pos == all {
    margin: #{$val}px !important;
  } @else if $pos == x {
    margin-left: #{$val}px !important;
    margin-right: #{$val}px !important;
  } @else if $pos == y {
    margin-top: #{$val}px !important;
    margin-bottom: #{$val}px !important;
  } @else {
    margin-#{$pos}: #{$val}px !important;
  }
}

@mixin padding($val: 1, $pos: all) {
  @if $pos == all {
    padding: #{$val}px !important;
  } @else if $pos == x {
    padding-left: #{$val}px !important;
    padding-right: #{$val}px !important;
  } @else if $pos == y {
    padding-top: #{$val}px !important;
    padding-bottom: #{$val}px !important;
  } @else {
    padding-#{$pos}: #{$val}px !important;
  }
}

@mixin border($pos: all, $c: $c-d8, $val: 1px) {
  @if $pos == all {
    border: #{$val} solid #{$c};
  } @else if $pos == x {
    border-left: #{$val} solid #{$c};
    border-right: #{$val} solid #{$c};
  } @else if $pos == y {
    border-top: #{$val} solid #{$c};
    border-left: #{$val} solid #{$c};
  } @else {
    border-#{$pos}: #{$val} solid #{$c};
  }
}

@mixin bg-img($url: '') {
  background-image: url('/public_static/teachingassistant/#{$url}');
}

@mixin border($w, $c, $pos: all) {
  @if $pos == all {
    border: #{$w}px solid $c;
  } @else if $pos == x {
    border-left: #{$w}px solid $c;
    border-right: #{$w}px solid $c;
  } @else if $pos == y {
    border-top: #{$w}px solid $c;
    border-bottom: #{$w}px solid $c;
  } @else {
    border-#{$pos}: #{$w}px solid $c;
  }
}

@mixin triangle($direction: bottom, $w: 30, $h: 20, $c: $c-main) {
  @if $direction == bottom {
    @include border($w / 2, transparent, x);
    @include border($h, $c, top);
  }
  @if $direction == top {
    @include border($w / 2, transparent, x);
    @include border($h, $c, bottom);
  }
  @if $direction == left {
    @include border($h / 2, transparent, y);
    @include border($w, $c, right);
  }
  @if $direction == right {
    @include border($h / 2, transparent, y);
    @include border($w, $c, left);
  }
}

@function transFormFontSize($px) {
  // $rem: 1.38;
  $rem: 1;
  @return ($px * $rem)+px;
}
@function fitPix3($px) {
  // $rem: 1.38;
  $rem: 1;
  @return ($px * $rem)+px;
}
@function fitPix4($px) {
  // $rem: 1.38;
  $rem: 1.335;
  @return ($px / $rem)+px;
}

$white: #fff;
$c-main: #1890ff;
$black: #000;
$c333: #333;
$c666: #666;
$c999: #999;
$c-d8: #D8D8D8;
$c-eb: #EBEBEB;
$c-text: #505253;
$m-hook: #3473f0;
$wrap: 180px;
$header-height: 80px;
$root-font-size:750/10rem;
$font-size:375/10rem;

@media screen and (min-device-width:1080px) {
   #root {
    // background-color: #1890ff;
   }
}

@import "./variable";
body {
  // max-width: 1920px;
  position: fixed;
  top:0;
  height: 100%;
  width: 100%;
  left: 0;
  margin: 0 auto;
  overflow: hidden;
  // height: 100%;
  background-color: #fafafa  ;

  min-width: 320px;
  font-size: 14px;
  font-family: -apple-system, PingFang SC, Helvetica, sans-serif;
  line-height: 1.5;
  color: #666;
  -webkit-text-size-adjust: 100% !important;
  padding-bottom: env(safe-area-inset-bottom);
  // overflow: hidden;

#root {
  overflow-x: hidden;
}

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  ul,
  li,
  div,
  dl,
  dt,
  dd {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-style: normal;
    font-size: 12px;
  }

  // i{ font-style: normal}
  a {
    text-decoration: none
  }
}

// i {
//   font-style: normal;
// }
[ant-click-animating-without-extra-node='false']::after,
.auto-bg {
  overflow: hidden;
  position: relative;
  background-size: cover;
  background: no-repeat center top;
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &-2 {
    overflow: hidden;
    line-height: 1.5;
    height: 3em;
  }

  &-3 {
    overflow: hidden;
    line-height: 1.5;
    height: 4.5em;
  }

  &-4 {
    overflow: hidden;
    line-height: 1.5;
    height: 6em;
  }

  &-5 {
    overflow: hidden;
    line-height: 1.5;
    height: 7.5em;
  }
}

.c {
  &-main {
    color: $c-main;
  }

  &-333 {
    color: $c333;
  }

  &-666 {
    color: $c666;
  }

  &-999 {
    color: $c999;
  }

  &-text {
    color: $c-text;
  }

  &-white {
    color: $white;
  }

  &-black {
    color: $black;
  }
}

.wrap {
  margin: $wrap;
}

@for $index from 1 through 60 {
  .f-#{$index} {
    font-size: #{$index}px;
  }

  .mag {
    &-#{$index} {
      @include margin($index);
    }

    &-x-#{$index} {
      @include margin($index, x);
    }

    &-y-#{$index} {
      @include margin($index, y);
    }

    &-t-#{$index} {
      @include margin($index, top);
    }

    &-b-#{$index} {
      @include margin($index, bottom);
    }

    &-l-#{$index} {
      @include margin($index, left);
    }

    &-r-#{$index} {
      @include margin($index, right);
    }
  }

  .pad {
    &-#{$index} {
      @include padding($index);
    }

    &-x-#{$index} {
      @include padding($index, x);
    }

    &-y-#{$index} {
      @include padding($index, y);
    }

    &-t-#{$index} {
      @include padding($index, top);
    }

    &-b-#{$index} {
      @include padding($index, bottom);
    }

    &-l-#{$index} {
      @include padding($index, left);
    }

    &-r-#{$index} {
      @include padding($index, right);
    }
  }
}

.echarts-for-react>div img {
  position: absolute;
  top: 18%;
  right: 18%;
  width: 35%;
  height: 35%;
}

.title {
  text-align: center;
  hyphenate: auto;
}


.p1 {
  text-align: justify;
  hyphenate: auto;
}

.p3 {
  text-align: center;
  font-family: Times New Roman;
  font-size: 10pt;
}

.sup {
  vertical-align: super;
  font-size: 18px !important;
}

sup {
  font-size: 18px !important;
}

sub {
  bottom: -0.55em !important;
  font-size: 18px !important;
}

.sub {
  vertical-align: sub;
  font-size: 18px !important;
}


.underline {
  text-decoration: underline;
}



.s1 {
  font-weight: bold;
}

.t1 {
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0;
}




.wordDivDot {
  position: relative;

  &::after {
    display: block;
    position: absolute;
    content: ' ';
    width: 4px;
    height: 4px;
    text-align: center;
    background-color: black;
    border-radius: 50%;
    left: 0;
    right: 0;
    margin: auto;
  }
}

.wordTestTable {
  width: 100%;
}

.mjx-char {
  // padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.mjx-charbox {
  width: auto !important;
}

u,
i {
  font-size: inherit;
}

// 分数字体大小
.mjx-numerator {
  // font-size: inherit !important;
}

em {
  font-style: italic !important;
  font-size: inherit !important;
}

b,
strong {
  font-size: inherit !important;
}

.rightTransForm {
  font-family: 'Noto', 'Sans', 'CJK', 'SC';
}

img {
  max-width: 100%;
}

.set-font-family div,
.set-font-family p {
  font-family: 'Times New Roman', Times, serif, KaiTi, SimSun !important;
}

mjx-container[jax="SVG"]>svg {
  // font-size: 1em !important;
  font-size: 1.05em !important;
  font-weight: lighter !important;
  // vertical-align: middle !important;
}

img,
svg {
  max-width: 100% !important;
}

// message icon 居中显示
.ant-message {
  .anticon {
    vertical-align: unset;
  }

}

.head_line {
  font-size: 36px;
}



input {
  -webkit-appearance: none;
}

@media only screen and (min-device-width: 1440px){
   html {
    font-size: 144px !important;
    // width: 1080px !important;
   }  

   body {
    // width: 1080px !important;
    #root {
       width: 1080px !important;
       margin:0 auto;
    }
      
   }
   
}
