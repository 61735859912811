$white: #fff;
$c-main: #1890ff;
$black: #000;
$c333: #333;
$c666: #666;
$c999: #999;
$c-d8: #D8D8D8;
$c-eb: #EBEBEB;
$c-text: #505253;
$m-hook: #3473f0;
$wrap: 180px;
$header-height: 80px;
$root-font-size:750/10rem;
$font-size:375/10rem;

@media screen and (min-device-width:1080px) {
   #root {
    // background-color: #1890ff;
   }
}
